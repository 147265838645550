// src/components/Hero.js

import React from 'react';
import { Link } from 'react-scroll';
const unicorn = '/images/unicorn.png';

export default function Hero() {
    return(
        <section className="hero" id="content">            
            <div className="content">
                <div className="columns">
                    <img src={unicorn} className="image unicorn" width="400" height="460" alt="" />
                    <div className="col">
                        <h1>More than a Designer</h1>
                        <p>With over a decade's experience, Lee Travaglini is a multi-talented unicorn in UX/UI, development, illustration, and marketing.</p>
                        <Link
                            to="experience"
                            href="experience"
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}
                            activeClass="active"
                            rel="nofollow"
                        >
                            Learn more
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}