import React, { useState } from "react";
import { projects } from "../data";
import Overlay from "./Overlay";

export default function Portfolio() {
  const [selectedProject, setSelectedProject] = useState(null);

  const handleProjectClick = (project) => {
    document.body.style.overflow = "hidden";
    setSelectedProject(project);
  };

  const handleCloseOverlay = () => {
    document.body.style.overflow = "auto";
    setSelectedProject(null);
  };

  return (
    <section className="container flex relative" id="portfolio">
      <aside className="sidebar">
        <span className="subtitle red">02. Portfolio</span>
        <h2 className="large">Some of my most recent work</h2>
        <p>
          A showcase of my skills in UX/UI, development and branding. Adapting
          to unique project demands, I find innovative solutions that blend
          creativity with functionality.
        </p>
      </aside>
      <div className="projects">
        {projects.map((project) => (
          <div
            className="project"
            key={project.key} // Use a unique identifier as the key
            onClick={() => handleProjectClick(project)}
          >
            <div className="inner">
              <h3 key={`title-${project.id}`}>{project.title}</h3> {/* Add a unique key to h3 */}
              <p>{project.skills}</p>
            </div>
            <img src={project.thumb} alt={project.title} />
          </div>
        ))}
      </div>
      {selectedProject && (
        <Overlay project={selectedProject} onClose={handleCloseOverlay} />
      )}
    </section>
  );
}
