import React from "react";
import Header from "./components/Header";
import MobileNav from "./components/Mobile-Nav";
import Hero from "./components/Hero";
import Experience from "./components/Experience";
import Portfolio from "./components/Portfolio";
import Knowledge from "./components/Knowledge";
import Footer from "./components/Footer";

export default function App() {
  return (
      <main>
        <Header />
        <MobileNav />
        <Hero />
        <Experience />
        <Portfolio />
        <Knowledge />
        <Footer />
      </main>
  );
}