// src/components/Knowledge.js

import React, { useState } from "react";
import { posts } from "../data";

export default function Knowledge() {
  const [displayedPosts, setDisplayedPosts] = useState(posts.slice(0, 3));

  const handleLoadMore = () => {
    const postsToAdd = posts.slice(displayedPosts.length, displayedPosts.length + 3);
    setDisplayedPosts([...displayedPosts, ...postsToAdd]);
  };

  return (
    <section className="container grey alt relative" id="knowledge-share">
      
      <div className="oracle"></div>
      
      <div className="intro">
        <span className="subtitle">03. Knowledge Share</span>
        <h2 className="large white">Sharing Design Experience and Insights</h2>
        <p className="white">
        I believe in sharing knowledge. Over the past decade in creative design, I've gained valuable tricks and insights.
        </p>
      </div>

      <div className="posts">
        {displayedPosts.map((post) => (
          <div className="post" key={post.key}>
            <div>
              <span className="subtitle">
                {post.category} &bull; <em>{post.read} read</em>
              </span>
              <a href={post.link} target="_blank" rel="noreferrer">
                <h3 className="large">{post.title}</h3>
              </a>
              <p className="white">{post.excerpt}</p>
              <a href={post.link} className="simpleBtn" target="_blank" rel="noreferrer">Read Article on Medium</a>
              
            </div>
            <a href={post.link} target="_blank" rel="noreferrer">
              <img src={post.thumb} alt={post.title} height="200" width="200" />
            </a>
          </div>
        ))}
      </div>
      {displayedPosts.length < posts.length && (
        <button className="btn" onClick={handleLoadMore}>
          Load More
        </button>
      )}
    </section>
  );
}