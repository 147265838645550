// src/components/Experience.js

import { skills } from "../data";
import { experiences } from "../data";

export default function Experience() {
    return(
        <section className="container grey relative" id="experience">
            <div className="innerContainer">

                <div className="crane"></div>

                <div className="intro">
                    <span className="subtitle redText">01. Professional Experience</span>
                    <h2 className="large">Learn a little something about me</h2>
                    <p><strong>Lee Travaglini</strong> is a UX/UI designer, front-end developer, and entrepreneur. He has created user-friendly products, developed full-suite campaigns, and has consulted on digital design and UX best practices.</p>
                </div>

                <h3>Skills</h3>

                <ul className="skills">
                {skills.map((skill, index) => (
                    <li key={index}> {/* Use a unique identifier as the key */}
                    <img src={skill.icon} alt="" width="24" height="24" />
                    <div>
                        <h4>{skill.title}</h4>
                        <p>{skill.description}</p>
                    </div>
                    </li>
                ))}
                </ul>

                <h3>Work Experience</h3>

                <ul className="experiences">
                {experiences.map((experience, index) => (
                    <li key={index}> {/* Use a unique identifier as the key */}
                        <img src={experience.logo} alt={experience.company} width="200" height="60" />
                        <div className="expColumns">
                            <div>
                                <h4>{experience.company}</h4>
                                <span className="title">{experience.title}</span>
                            </div>
                            <div>
                                <em>{experience.time}<br />
                                {experience.location}</em>
                            </div>
                        </div>
                    </li>
                ))}
                </ul>



            </div>
        </section>
    );
}