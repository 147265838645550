import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSolid, setIsSolid] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      if (currentScrollPos > prevScrollPos) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }

      if (currentScrollPos > 120) {
        setIsSolid(true);
      } else {
        setIsSolid(false);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  const headerClassName = `header ${isScrolled ? 'off-screen' : 'scrolled'} ${isSolid ? 'solid' : ''}`;

  return (
    <header className={headerClassName}>
      <Link
        to="content"
        href="#content"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        className="logo"
        rel="nofollow"
      >
      Lee Travaglini<br /><span>UX/UI Designer</span>
      </Link>

      <nav className="menu">
        <ul>
          <li>
            <Link
              to="experience"
              href="#experience"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              activeClass="active"
              rel="nofollow"
            >
              Experience
            </Link>
          </li>
          <li>
            <Link
              to="portfolio"
              href="#portfolio"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              activeClass="active"
              rel="nofollow"
            >
              Portfolio
            </Link>
          </li>
          <li>
            <Link
              to="knowledge-share"
              href="#knowledge-share"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              activeClass="active"
              rel="nofollow"
            >
              Knowledge Share
            </Link>
          </li>
        </ul>
        <Link
          to="contact"
          href="#contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          activeClass="active"
          className="btn"
          rel="nofollow"
        >
          Get in Touch
        </Link>
      </nav>
    </header>
  );
  
}
