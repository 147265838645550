import { useState, useEffect } from "react";

const Overlay = ({ project, onClose }) => {
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    setShowOverlay(true);
  }, []);

  const handleClose = () => {
    setShowOverlay(false);
    setTimeout(() => {
      onClose();
    }, 300); // Wait for the animation to complete before closing the overlay
  };

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains("overlay")) {
      handleClose();
    }
  };

  return (
    <div
      className={`overlay ${showOverlay ? "slideIn" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className="slideOut" style={{ overflowY: 'auto' }}>
        <img src={project.hero} alt={project.title} />
        <button onClick={handleClose} className="back">Back</button>
        <div className="inner">
          <h2 className="projectTitle">{project.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: project.description }} />
        </div>
      </div>
    </div>
  );
};

export default Overlay;
