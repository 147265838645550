import { Link } from 'react-scroll';

export default function MobileNav() {
  return (
    <nav className="mobileMenu">
      <ul>
        <li>
          <Link
            to="experience"
            href="#experience"
            spy={true}
            smooth={true}
            duration={500}
            activeClass="active"
            rel="nofollow"
          >
            Experience
          </Link>
        </li>
        <li>
          <Link
            to="portfolio"
            href="#portfolio"
            spy={true}
            smooth={true}
            duration={500}
            activeClass="active"
            rel="nofollow"
          >
            Portfolio
          </Link>
        </li>
        <li>
          <Link
            to="knowledge-share"
            href="#knowledge-share"
            spy={true}
            smooth={true}
            duration={500}
            activeClass="active"
            rel="nofollow"
          >
            Articles
          </Link>
        </li>
        <li>
          <Link
            to="contact"
            href="#contact"
            spy={true}
            smooth={true}
            duration={500}
            activeClass="active"
            rel="nofollow"
          >
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
  
}
